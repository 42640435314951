import * as Dialog from '@radix-ui/react-dialog'
import { motion } from 'framer-motion'
import * as React from 'react'
import cn from 'classnames'
import { Overlay } from '../../atoms/Overlay'
import styles from './Modal.module.scss'
import { fadeVariant } from '../../../utils/variants'

export type RootProps = {
  /**
   * An element used to open/close the modal
   */
  trigger?: React.ReactElement
  /**
   * A title of the content of the modal, to announce when it opens
   */
  title: string
  /**
   * Show or hide the overlay which covers the document content while the modal is open
   * @defaultValue `true`
   */
  overlay?: boolean
  /**
   * Content of the modal
   */
  children: React.ReactNode
  className?: string

  /**
   * Callback when the user interacts outside of the modal
   * Return `false` to prevent the modal from closing
   * @param isOverlay `true` if the user interacted with the overlay
   */
  onInteractOutside?: (onInteractOutsideProps: {
    isOverlay: boolean
  }) => boolean

  ignoreOutsideClick?: boolean
} & Omit<Dialog.DialogProps, 'title'>

export const Root = ({
  children,
  className,
  defaultOpen = false,
  onInteractOutside,
  onOpenChange,
  open,
  overlay = true,
  ignoreOutsideClick = false,
  title,
  trigger,
  ...restProps
}: RootProps) => {
  const overlayRef = React.useRef<HTMLDivElement>(null)

  return (
    <Dialog.Root
      defaultOpen={defaultOpen}
      modal={overlay}
      onOpenChange={onOpenChange}
      open={open}
    >
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Portal>
        {overlay && (
          <Dialog.Overlay>
            <Overlay ref={overlayRef} />
          </Dialog.Overlay>
        )}
        <Dialog.Content
          className={cn(styles.wrapper)}
          onInteractOutside={(e) => {
            if (!overlay) e.preventDefault()
            if (ignoreOutsideClick) e.preventDefault()
            if (!onInteractOutside || !overlayRef.current) return

            const isOverlay = overlayRef.current.contains(e.target as Node)
            const allowClick = onInteractOutside({ isOverlay })
            if (!allowClick) e.preventDefault() // prevent the modal from closing
          }}
          {...restProps}
        >
          <motion.div
            animate="active"
            className={cn(styles.content, className)}
            exit="inactive"
            initial="inactive"
            transition={{ duration: 0.2, delay: 0.1 }}
            variants={fadeVariant}
          >
            <Dialog.Title className="sr-only">{title}</Dialog.Title>
            {children}
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export const CloseButton = Dialog.Close
export type CloseButtonProps = Dialog.DialogCloseProps
